.bg-gradient-primary {
    background-color: #4e73df;
    background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%);
    background-size: cover;
}
.d-flex {
    display: flex !important;
}

.accordion {
    overflow-anchor: none;
}

.accordion > .card {
    overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

.text-uppercase, .dropdown .dropdown-menu .dropdown-header, .sidebar .sidebar-heading {
    text-transform: uppercase !important;
}
.animated--grow-in, .sidebar .nav-item .collapse {
    -webkit-animation-name: growIn;
    animation-name: growIn;
    -webkit-animation-duration: 200ms;
    animation-duration: 200ms;
    -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
    animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
}


.topbar #sidebarToggleTop {
    height: 2.5rem;
    width: 2.5rem;
}

.topbar #sidebarToggleTop:hover {
    background-color: #eaecf4;
}

.topbar #sidebarToggleTop:active {
    background-color: #dddfeb;
}


.sidebar .nav-item.dropdown .dropdown-toggle::after,
.topbar .nav-item.dropdown .dropdown-toggle::after {
    width: 1rem;
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: '\f105';
}

.sidebar .nav-item.dropdown.show .dropdown-toggle::after,
.topbar .nav-item.dropdown.show .dropdown-toggle::after {
    content: '\f107';
}

.sidebar .nav-item .nav-link,
.topbar .nav-item .nav-link {
    position: relative;
}

.sidebar .nav-item .nav-link .badge-counter,
.topbar .nav-item .nav-link .badge-counter {
    position: absolute;
    transform: scale(0.7);
    transform-origin: top right;
    right: .25rem;
    margin-top: -.25rem;
}

.sidebar .nav-item .nav-link .img-profile,
.topbar .nav-item .nav-link .img-profile {
    height: 2rem;
    width: 2rem;
}


.sidebar {
    width: 6.5rem;
    min-height: 100vh;
}

.sidebar .nav-item {
    position: relative;
}

.sidebar .nav-item:last-child {
    margin-bottom: 1rem;
}

.sidebar .nav-item .nav-link {
    text-align: center;
    padding: 0.75rem 1rem;
    width: 6.5rem;
}

.sidebar .nav-item .nav-link span {
    font-size: 0.65rem;
    display: block;
}

.sidebar .nav-item.active .nav-link {
    font-weight: 700;
}

.sidebar .nav-item .collapse {
    position: absolute;
    left: calc(6.5rem + 1.5rem / 2);
    z-index: 1;
    top: 2px;
}

.sidebar .nav-item .collapse .collapse-inner {
    border-radius: 0.35rem;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.sidebar .nav-item .collapsing {
    display: none;
    transition: none;
}

.sidebar .nav-item .collapse .collapse-inner,
.sidebar .nav-item .collapsing .collapse-inner {
    padding: .5rem 0;
    min-width: 10rem;
    font-size: 0.85rem;
    margin: 0 0 1rem 0;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-header,
.sidebar .nav-item .collapsing .collapse-inner .collapse-header {
    margin: 0;
    white-space: nowrap;
    padding: .5rem 1.5rem;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 0.65rem;
    color: #b7b9cc;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item {
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    display: block;
    color: #3a3b45;
    text-decoration: none;
    border-radius: 0.35rem;
    white-space: nowrap;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item:hover,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
    background-color: #eaecf4;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item:active,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item:active {
    background-color: #dddfeb;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item.active,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
    color: #4e73df;
    font-weight: 700;
}

.sidebar #sidebarToggle {
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    margin-bottom: 1rem;
    cursor: pointer;
}

.sidebar #sidebarToggle::after {
    font-weight: 900;
    content: '\f104';
    margin-right: 0.1rem;
}

.sidebar #sidebarToggle:hover {
    text-decoration: none;
}

.sidebar #sidebarToggle:focus {
    outline: none;
}

.sidebar.toggled {
    width: 0 !important;
    overflow: hidden;
}

.sidebar.toggled #sidebarToggle::after {
    content: '\f105';
    margin-left: 0.25rem;
}

.sidebar.toggled .sidebar-card {
    display: none;
}

.sidebar .sidebar-brand {
    height: 4.375rem;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 800;
    padding: 1.5rem 1rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    z-index: 1;
}

.sidebar .sidebar-brand .sidebar-brand-icon i {
    font-size: 2rem;
}

.sidebar .sidebar-brand .sidebar-brand-text {
    display: none;
}

.sidebar hr.sidebar-divider {
    margin: 0 1rem 1rem;
}

.sidebar .sidebar-heading {
    text-align: center;
    padding: 0 1rem;
    font-weight: 800;
    font-size: 0.65rem;
}

.sidebar .sidebar-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.875rem;
    border-radius: 0.35rem;
    color: rgba(255, 255, 255, 0.8);
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.1);
}

.sidebar .sidebar-card .sidebar-card-illustration {
    height: 3rem;
    display: block;
}

.sidebar .sidebar-card .sidebar-card-title {
    font-weight: bold;
}

.sidebar .sidebar-card p {
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 768px) {
    .sidebar {
        width: 14rem !important;
    }
    .sidebar .nav-item .collapse {
        position: relative;
        left: 0;
        z-index: 1;
        top: 0;
        -webkit-animation: none;
        animation: none;
    }
    .sidebar .nav-item .collapse .collapse-inner {
        border-radius: 0;
        box-shadow: none;
    }
    .sidebar .nav-item .collapsing {
        display: block;
        transition: height 0.15s ease;
    }
    .sidebar .nav-item .collapse,
    .sidebar .nav-item .collapsing {
        margin: 0 1rem;
    }
    .sidebar .nav-item .nav-link {
        display: block;
        width: 100%;
        text-align: left;
        padding: 1rem;
        width: 14rem;
    }
    .sidebar .nav-item .nav-link i {
        font-size: 0.85rem;
        margin-right: 0.25rem;
    }
    .sidebar .nav-item .nav-link span {
        font-size: 0.85rem;
        display: inline;
    }
    .sidebar .nav-item .nav-link[data-toggle="collapse"]::after {
        width: 1rem;
        text-align: center;
        float: right;
        vertical-align: 0;
        border: 0;
        font-weight: 900;
        content: '\f107';
    }
    .sidebar .nav-item .nav-link[data-toggle="collapse"].collapsed::after {
        content: '\f105';
    }
    .sidebar .sidebar-brand .sidebar-brand-icon i {
        font-size: 2rem;
    }
    .sidebar .sidebar-brand .sidebar-brand-text {
        display: inline;
    }
    .sidebar .sidebar-heading {
        text-align: left;
    }
    .sidebar.toggled {
        overflow: visible;
        width: 6.5rem !important;
    }
    .sidebar.toggled .nav-item .collapse {
        position: absolute;
        left: calc(6.5rem + 1.5rem / 2);
        z-index: 1;
        top: 2px;
        -webkit-animation-name: growIn;
        animation-name: growIn;
        -webkit-animation-duration: 200ms;
        animation-duration: 200ms;
        -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
        animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
    }
    .sidebar.toggled .nav-item .collapse .collapse-inner {
        box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
        border-radius: 0.35rem;
    }
    .sidebar.toggled .nav-item .collapsing {
        display: none;
        transition: none;
    }
    .sidebar.toggled .nav-item .collapse,
    .sidebar.toggled .nav-item .collapsing {
        margin: 0;
    }
    .sidebar.toggled .nav-item:last-child {
        margin-bottom: 1rem;
    }
    .sidebar.toggled .nav-item .nav-link {
        text-align: center;
        padding: 0.75rem 1rem;
        width: 6.5rem;
    }
    .sidebar.toggled .nav-item .nav-link span {
        font-size: 0.65rem;
        display: block;
    }
    .sidebar.toggled .nav-item .nav-link i {
        margin-right: 0;
    }
    .sidebar.toggled .nav-item .nav-link[data-toggle="collapse"]::after {
        display: none;
    }
    .sidebar.toggled .sidebar-brand .sidebar-brand-icon i {
        font-size: 2rem;
    }
    .sidebar.toggled .sidebar-brand .sidebar-brand-text {
        display: none;
    }
    .sidebar.toggled .sidebar-heading {
        text-align: center;
    }
}

.sidebar-light .sidebar-brand {
    color: #6e707e;
}

.sidebar-light hr.sidebar-divider {
    border-top: 1px solid #eaecf4;
}

.sidebar-light .sidebar-heading {
    color: #b7b9cc;
}

.sidebar-light .nav-item .nav-link {
    color: #858796;
}

.sidebar-light .nav-item .nav-link i {
    color: #d1d3e2;
}

.sidebar-light .nav-item .nav-link:active, .sidebar-light .nav-item .nav-link:focus, .sidebar-light .nav-item .nav-link:hover {
    color: #6e707e;
}

.sidebar-light .nav-item .nav-link:active i, .sidebar-light .nav-item .nav-link:focus i, .sidebar-light .nav-item .nav-link:hover i {
    color: #6e707e;
}

.sidebar-light .nav-item .nav-link[data-toggle="collapse"]::after {
    color: #b7b9cc;
}

.sidebar-light .nav-item.active .nav-link {
    color: #6e707e;
}

.sidebar-light .nav-item.active .nav-link i {
    color: #6e707e;
}

.sidebar-light #sidebarToggle {
    background-color: #eaecf4;
}

.sidebar-light #sidebarToggle::after {
    color: #b7b9cc;
}

.sidebar-light #sidebarToggle:hover {
    background-color: #dddfeb;
}

.sidebar-dark .sidebar-brand {
    color: #fff;
}

.sidebar-dark hr.sidebar-divider {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.sidebar-dark .sidebar-heading {
    color: rgba(255, 255, 255, 0.4);
}

.sidebar-dark .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.8);
}

.sidebar-dark .nav-item .nav-link i {
    color: rgba(255, 255, 255, 0.3);
}

.sidebar-dark .nav-item .nav-link:active, .sidebar-dark .nav-item .nav-link:focus, .sidebar-dark .nav-item .nav-link:hover {
    color: #fff;
}

.sidebar-dark .nav-item .nav-link:active i, .sidebar-dark .nav-item .nav-link:focus i, .sidebar-dark .nav-item .nav-link:hover i {
    color: #fff;
}

.sidebar-dark .nav-item .nav-link[data-toggle="collapse"]::after {
    color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark .nav-item.active .nav-link {
    color: #fff;
}

.sidebar-dark .nav-item.active .nav-link i {
    color: #fff;
}

.sidebar-dark #sidebarToggle {
    background-color: rgba(255, 255, 255, 0.2);
}

.sidebar-dark #sidebarToggle::after {
    color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark #sidebarToggle:hover {
    background-color: rgba(255, 255, 255, 0.25);
}

.sidebar-dark.toggled #sidebarToggle::after {
    color: rgba(255, 255, 255, 0.5);
}
body.sidebar-toggled footer.sticky-footer {
    width: 100%;
}

#admin-wrapper {
    display: flex;
}

#admin-wrapper #admin-content-wrapper {
    background-color: #f8f9fc;
    width: 100%;
    overflow-x: hidden;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

#admin-wrapper #admin-content-wrapper #content {
    flex: 1 0 auto;
}


.card .card-header .dropdown {
    line-height: 1;
}

.card .card-header .dropdown .dropdown-menu {
    line-height: 1.5;
}

.card .card-header[data-toggle="collapse"] {
    text-decoration: none;
    position: relative;
    padding: 0.75rem 3.25rem 0.75rem 1.25rem;
}

.card .card-header[data-toggle="collapse"]::after {
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 1.725rem;
    line-height: 51px;
    font-weight: 900;
    content: '\f107';
    color: #d1d3e2;
}

.card .card-header[data-toggle="collapse"].collapsed {
    border-radius: 0.35rem;
}

.card .card-header[data-toggle="collapse"].collapsed::after {
    content: '\f105';
}


.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e3e6f0;
    border-radius: 0.35rem;
}

.card > hr {
    margin-right: 0;
    margin-left: 0;
}

.card > .list-group {
    border-top: inherit;
    border-bottom: inherit;
}

.card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(0.35rem - 1px);
    border-top-right-radius: calc(0.35rem - 1px);
}

.card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(0.35rem - 1px);
    border-bottom-left-radius: calc(0.35rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
    border-top: 0;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link + .card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #f8f9fc;
    border-bottom: 1px solid #e3e6f0;
}

.card-header:first-child {
    border-radius: calc(0.35rem - 1px) calc(0.35rem - 1px) 0 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: #f8f9fc;
    border-top: 1px solid #e3e6f0;
}

.card-footer:last-child {
    border-radius: 0 0 calc(0.35rem - 1px) calc(0.35rem - 1px);
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    border-radius: calc(0.35rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
    flex-shrink: 0;
    width: 100%;
}

.card-img,
.card-img-top {
    border-top-left-radius: calc(0.35rem - 1px);
    border-top-right-radius: calc(0.35rem - 1px);
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: calc(0.35rem - 1px);
    border-bottom-left-radius: calc(0.35rem - 1px);
}

.card-deck .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-deck {
        display: flex;
        flex-flow: row wrap;
        margin-right: -0.75rem;
        margin-left: -0.75rem;
    }
    .card-deck .card {
        flex: 1 0 0%;
        margin-right: 0.75rem;
        margin-bottom: 0;
        margin-left: 0.75rem;
    }
}

.card-group > .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap;
    }
    .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0;
    }
    .card-group > .card + .card {
        margin-left: 0;
        border-left: 0;
    }
    .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-top,
    .card-group > .card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-bottom,
    .card-group > .card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-top,
    .card-group > .card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-bottom,
    .card-group > .card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        -moz-column-count: 3;
        column-count: 3;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}


.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #858796;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e3e6f0;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e3e6f0;
}

.table tbody + tbody {
    border-top: 2px solid #e3e6f0;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid #e3e6f0;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #e3e6f0;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
    color: #858796;
    background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
    background-color: #cdd8f6;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
    border-color: #a3b6ee;
}

.table-hover .table-primary:hover {
    background-color: #b7c7f2;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
    background-color: #b7c7f2;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
    background-color: #dddde2;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
    border-color: #c0c1c8;
}

.table-hover .table-secondary:hover {
    background-color: #cfcfd6;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
    background-color: #cfcfd6;
}

.table-success,
.table-success > th,
.table-success > td {
    background-color: #bff0de;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
    border-color: #89e2c2;
}

.table-hover .table-success:hover {
    background-color: #aaebd3;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
    background-color: #aaebd3;
}

.table-info,
.table-info > th,
.table-info > td {
    background-color: #c7ebf1;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
    border-color: #96dbe4;
}

.table-hover .table-info:hover {
    background-color: #b3e4ec;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
    background-color: #b3e4ec;
}

.table-warning,
.table-warning > th,
.table-warning > td {
    background-color: #fceec9;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
    border-color: #fadf9b;
}

.table-hover .table-warning:hover {
    background-color: #fbe6b1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
    background-color: #fbe6b1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
    background-color: #f8ccc8;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
    border-color: #f3a199;
}

.table-hover .table-danger:hover {
    background-color: #f5b7b1;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
    background-color: #f5b7b1;
}

.table-light,
.table-light > th,
.table-light > td {
    background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
    border-color: #fbfcfd;
}

.table-hover .table-light:hover {
    background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
    background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
    background-color: #d1d1d5;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #a9aab1;
}

.table-hover .table-dark:hover {
    background-color: #c4c4c9;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
    background-color: #c4c4c9;
}

.table-active,
.table-active > th,
.table-active > td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #fff;
    background-color: #5a5c69;
    border-color: #6c6e7e;
}

.table .thead-light th {
    color: #6e707e;
    background-color: #eaecf4;
    border-color: #e3e6f0;
}

.table-dark {
    color: #fff;
    background-color: #5a5c69;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #6c6e7e;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm > .table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md > .table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg > .table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl > .table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
    border: 0;
}


@media print {
    *,
    *::before,
    *::after {
        text-shadow: none !important;
        box-shadow: none !important;
    }
    a:not(.btn) {
        text-decoration: underline;
    }
    abbr[title]::after {
        content: " (" attr(title) ")";
    }
    pre {
        white-space: pre-wrap !important;
    }
    pre,
    blockquote {
        border: 1px solid #b7b9cc;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr,
    img {
        page-break-inside: avoid;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
    @page {
        size: a3;
    }
    body {
        min-width: 992px !important;
    }
    .container {
        min-width: 992px !important;
    }
    .navbar {
        display: none;
    }
    .badge {
        border: 1px solid #000;
    }
    .table {
        border-collapse: collapse !important;
    }
    .table td,
    .table th {
        background-color: #fff !important;
    }
    .table-bordered th,
    .table-bordered td {
        border: 1px solid #dddfeb !important;
    }
    .table-dark {
        color: inherit;
    }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
        border-color: #e3e6f0;
    }
    .table .thead-dark th {
        color: inherit;
        border-color: #e3e6f0;
    }
}
